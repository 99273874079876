import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import Image from 'next/image'
class Awards extends Component {
  Col;
  render() {
    return (
        <div className="awardlogos">
          <Carousel
            slidesPerPage={5}
            infinite
            dots
            autoPlay={3000}
            stopAutoPlayOnHover={true}
            breakpoints={{
              640: {
                slidesPerPage: 2,
                arrows: false,
              },
              900: {
                slidesPerPage: 3,
                arrows: false,
              },
              1024: {
                slidesPerPage: 4,
                arrows: false,
              },
            }}
          >
            <div>
              <Image
                  src="/images/Action_For_India.webp"
                  width={200}
                  height={200}
                  alt="Action For India"
                  className="w-100 h-auto"
                />
            </div>
            <div>
              <Image
                  src="/images/Best_Indian_Social_Enterprise_Award.webp"
                  width={200}
                  height={200}
                  alt="Best Indian Social Enterprise Award"
                  className="w-100 h-auto"
                />
            </div>
            <div>
              <Image
                  src="/images/BusinessLeadershipAward.webp"
                  width={200}
                  height={200}
                  alt="Business Leadership Award"
                  className="w-100 h-auto"
                />
            </div>
            <div>
              <Image
                  src="/images/CNBCAward.webp"
                  width={200}
                  height={200}
                  alt="CNBC Award"
                  className="w-100 h-auto"
                />
            </div>
            <div>
              <Image
                  src="/images/Emerging_entrepreneur_of_the_year.webp"
                  width={200}
                  height={200}
                  alt="Emerging entrepreneur of the year"
                  className="w-100 h-auto"
                />
            </div>
            <div>
              <Image
                  src="/images/Entrepreneurs_Award.webp"
                  width={200}
                  height={200}
                  alt="Entrepreneurs Award"
                  className="w-100 h-auto"
                />
            </div>
            <div>
              <Image
                  src="/images/Future_of_Ind.webp"
                  width={200}
                  height={200}
                  alt="Future of India"
                  className="w-100 h-auto"
                />
            </div>
            <div>
              <Image
                  src="/images/Future_of_India.webp"
                  width={200}
                  height={200}
                  alt="Future of India"
                  className="w-100 h-auto"
                />
            </div>
            <div>
              <Image
                  src="/images/Startup_Curation.webp"
                  width={200}
                  height={200}
                  alt="Startup Curation"
                  className="w-100 h-auto"
                />
            </div>
            <div>
              <Image
                  src="/images/Thank_You.webp"
                  width={200}
                  height={200}
                  alt="Thank You"
                  className="w-100 h-auto"
                />
            </div>
            <div>
              <Image
                  src="/images/TSS_Emerging_social_enterprise_of_the_year.webp"
                  width={200}
                  height={200}
                  alt="TSS Emerging social enterprise of the year"
                  className="w-100 h-auto"
                />
            </div>
            <div>
              <Image
                  src="/images/TSSAward.webp"
                  width={200}
                  height={200}
                  alt="TSS Award"
                  className="w-100 h-auto"
                />
            </div>
          </Carousel>
        </div>
    );
  }
}

export default Awards;
